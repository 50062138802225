import React from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { Layout } from '../components/common';
import ErrorIcon from '../images/404';

import style from '../styles/pages/404.scss';

import { navigate } from 'gatsby';

const NotFoundPage = () => {
  const handleClick = () => {
    navigate('/');
  };

  return (
    <Layout headerClassName={style.header}>
      <div className={style.notFoundPage}>
        <div className="container">
          <ErrorIcon />
          <h2>Congrats, you’ve found a blank page!</h2>
          <p>But nothing really happens here. So maybe click that button below and try again.</p>
          <PrimaryButton onClick={handleClick}>Go Home</PrimaryButton>
        </div>
      </div>

      <BigBanner />
    </Layout>
  );
};

export default NotFoundPage;
